import * as React from 'react';
import {useState} from 'react';
import Faq5 from '../partials/faq-5';
import FeaturesWithImage from '../partials/features-with-image';
import Header from '../partials/header';
import {PAGE_DATA} from '../text-content/content';
import {PageContentContext} from './page-content.context';
import Footer from "../partials/footer-5";
import MultiFeaturesWithImage from "../partials/multi-features-with-image";
import ShapeBackground from "../partials/shape-background";
import TeamMembers from "../partials/team-members-5";
import TokenDistribution from "../partials/token-distribution";
import Roadmap5 from "../partials/roadmap-5";

export function Index() {
    const [pageState, setPageState] = useState(PAGE_DATA);
    return (
        <PageContentContext.Provider value={pageState}>
            <Header hero={PAGE_DATA.hero} navigation={PAGE_DATA.navigation} appLink={PAGE_DATA.appLink}></Header>
            <MultiFeaturesWithImage></MultiFeaturesWithImage>
            <FeaturesWithImage plannedFeatures={PAGE_DATA.plannedFeatures}></FeaturesWithImage>
            {/*<FeaturesWithIcons></FeaturesWithIcons>*/}
            <Roadmap5></Roadmap5>
            <Faq5 faq={PAGE_DATA.faq} faqIntro={PAGE_DATA.faqIntro}></Faq5>
            <TokenDistribution></TokenDistribution>
            {/*<TeamMembers></TeamMembers>*/}
            <Footer></Footer>
            <ShapeBackground></ShapeBackground>
        </PageContentContext.Provider>
    );
}


export default Index;
