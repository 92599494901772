import * as React from 'react';
import {useContext} from "react";
import {PageContentContext} from "../pages/page-content.context";

function Footer(props: {}) {
  const data = useContext(PageContentContext);
  const links = data.navigation.map((el, i) => {
    return <li key={`nav-item-key-` + i + 1}>
      <a href={el.link}>
        {el.text}
      </a>
    </li>;
  });

  return <footer id="footer-05" className="footer">
    <div className="container">
      <div className="row footer-btm-wrapper">
        <div className="col-md-12">
          <div className="footer_items">
            <div className="footer-single-col footer_single_list_1">
              <p>
                All of our services are offered as is, we do not offer any guarantee or
                warranty for the app.
              </p>
              {/*<ul className="social-items list-unstyled">*/}
              {/*  <li>*/}
              {/*    <a href="#">*/}
              {/*      <i className="fab fa-facebook-f fb-icon"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <a href="#">*/}
              {/*      <i className="fab fa-instagram instagram-icon"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <a href="#">*/}
              {/*      <i className="fab fa-twitter twitt-icon"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <a href="#">*/}
              {/*      <i className="fab fa-youtube youtube-icon"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>

            <div className="footer-single-col footer_single_list_2">
              <h3 className="subtitle_1">Company</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
              </ul>
            </div>

            <div className="footer-single-col footer_single_list_3">
              <h3 className="subtitle_1">Information</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Technology</a>
                </li>
                <li>
                  <a href="#">Github</a>
                </li>
                <li>
                  <a href="#">Light Paper</a>
                </li>
              </ul>
            </div>

            <div className="footer-single-col footer_single_list_4">
              <h3 className="subtitle_1">Product</h3>
              <ul className="list-unstyled">
                {links}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span className="shape1 header-shape">
      <img src="images/shape/header5_shape_1.png" alt=""/>
    </span>
    <span className="shape2 header-shape">
      <img src="images/shape/header5_shape_2.png" alt=""/>
    </span>
    <span className="shape3 header-shape">
      <img src="images/shape/header5_shape_3.png" alt=""/>
    </span>
  </footer>
};

export default Footer;
