import React from 'react';

function TokenDistribution(props: {}) {
    return (
        <section id="token_distribution_06">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="sub-title">
                            <h2 className=" wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">IFELSE Token
                                Distribution</h2>
                        </div>
                    </div>
                </div>
                <div className="row distribution_single_items">
                    <div className="col-sm-12 col-md-6 col-lg-6 distribution_single_item  wow fadeInUp"
                         data-wow-duration="2s" data-wow-delay=".2s">
                        <div className="distribution_single">
                            <div className="distribution_wrapper">
                                <div className="distribution_details">
                                    <h3>IFELSE</h3>
                                    <div className="token_shape">
                                        <span className="shape1 header-shape"><img src="images/shape/home_6/token_1.png"
                                                                                   alt="" /></span>
                                        <span className="shape2 header-shape"><img src="images/shape/home_6/token_2.png"
                                                                                   alt="" /></span>
                                        <span className="shape3 header-shape"><img src="images/shape/home_6/token_3.png"
                                                                                   alt="" /></span>
                                        <span className="shape4 header-shape"><img src="images/shape/home_6/token_4.png"
                                                                                   alt="" /></span>
                                        <span className="shape5 header-shape"><img src="images/shape/home_6/token_5.png"
                                                                                   alt="" /></span>
                                    </div>
                                </div>
                                <ul className="details_content">
                                    <li className="details details_1">
                                        <p>Public Sales</p>
                                        <div className="details_caption">
                                            <span>45%</span>
                                        </div>
                                    </li>
                                    <li className="details details_2">
                                        <p>Strategic Partners</p>
                                        <div className="details_caption">
                                            <span>5%</span>
                                        </div>
                                    </li>
                                    <li className="details details_3">
                                        <p>Reserve Fund</p>
                                        <div className="details_caption">
                                            <span>20%</span>
                                        </div>
                                    </li>
                                    <li className="details details_4">
                                        <div className="details_caption">
                                            <span>20%</span>
                                        </div>
                                        <p>Development</p>
                                    </li>
                                    <li className="details details_6">
                                        <div className="details_caption">
                                            <span>10%</span>
                                        </div>
                                        <p>Marketing</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 distribution_single_item  wow fadeInUp distribution-description"
                         data-wow-duration="2s" data-wow-delay=".3s">
                        <h3 className="supply">Supply 10 000 000</h3>
                        <p>Pay for order execution with IFELSE</p>
                        <p>Get access to unique products</p>
                        <p>Get a lower commission</p>
                        <p>Pay for operations with IFELSE</p>
                        <p>Earn IFELSE from executing your strategy by your followers</p>
                        <p>Stake IFELSE to get bonuses</p>
                    </div>
                </div>
            </div>
            <div id="particles6-js" className="particles"></div>
        </section>
    );
}

export default TokenDistribution;