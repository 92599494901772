import {PageContent} from './content.interface';

export const PAGE_DATA: PageContent = {
    hero: {
        title: 'strategy trading platform',
        desc: 'Emotionless trading on best centralized and decentralized exchanges',
        img: '/images/header-06.png'
    },
    appLink: 'https://alpha.ifelse.finance',
    multiFeatures: {
        title: 'Features in Beta App',
        subtitle: 'We already implemented a lot. Below you can check genuine application screenshots and description of IFELSE main features',
        features: [
            {
                image: '/images/screenshots/single-order-conditions.jpeg',
                textToRight: false,
                text: [
                    {
                        title: 'Conditional orders',
                        description: 'You can leverage multiple types of signals that can be used to create conditional trades based on price movements, candles, other order states or even external signals from TradingView',
                        image: '/images/screenshots/order-summary.jpeg',
                    },
                ]
            },
            {
                textToRight: false,
                fullWidth: true,
                dark: true,
                text: [
                    {
                        title: 'Dark mode and user friendly UI',
                        description: 'Best user experience is our goal. We want our UI to be better than on many native exchanges!',
                        image: '/images/screenshots/full-view-dark.jpeg',
                    },
                ]
            },
            {
                image: '/images/screenshots/order-templates-multi.jpg',
                textToRight: false,
                fullWidth: true,
                text: [
                    {
                        title: 'Order templates',
                        description: 'Create your own, reusable order templates. Later on you can just click two buttons to execute your order with your own conditions in a second',
                    }
                ]
            },
            {
                image: '/images/screenshots/batch-order-summary.jpeg',
                textToRight: true,
                text: [
                    {
                        subtitle: 'Custom strategies',
                        description: 'You can build multiple strategies (batch order templates) that you can reuse with different assets and market conditions.',
                    },
                    {
                        description: 'Execute custom order setups that represents multiple atomic conditional orders, eg. to buy at some price an asset by spending only some percentage of your overall portfolio, then gradually take profit and register multiple stop losses on different market levels based on candle or price conditions.'
                    },
                    {
                        description: 'With our conditional trading mechanism your can create whatever strategies you need!'
                    },
                ]
            },
            {
                textToRight: false,
                fullWidth: true,
                dark: true,
                text: [
                    {
                        title: 'Portfolio and performance overview',
                        description: 'Visualize your assets portfolio from different exchanges in one place and check your trades performance on one simple chart',
                        image: '/images/screenshots/portfolio-overview-dark.jpeg',
                    },
                    {
                        image: '/images/screenshots/orders-table-dark.jpeg',
                        title: 'Trading journal',
                        description: 'Take notes on each trade in order to analyze the reasons for the trade. Mark your trades with multiple tags in order to quickly filter your trade history',
                    }
                ]
            }
        ]
    },
    plannedFeatures: [
        {
            title: 'DEX and CEX integration',
            description: 'We are adding integration with multiple centralized and decentralized exchanges like Binance, Kucoin, Pancake, Sushiswap, Uniswap. You would be able to swap your coins on decentralised exchanges and execute your trades based on your custom strategies. We are crypto focused startup and works to integrate IFELSE app with main DEFI ecosystems',
            image: '/images/about-2-02.png',
            textToRight: false
        },
        {
            title: 'Share your trades strategies and earn IFELSE',
            description: 'We are introducing brand new business model for all professional traders. Earn IFELS tokens by creating setups and strategies for your followers. If they execute your trading setups you will be rewarded in IFELSE tokens',
            image: '/images/about-5-02.png',
            textToRight: true
        },

        {
            title: 'Trades Analytics',
            description: 'IFELSE will automatically track and visualize trade-specific analytics such as return per strategy execution, overall return, risk, and more.',
            image: '/images/index-about.png',
            textToRight: false
        },
        {
            title: 'Trading Bot',
            description: 'We build our infrastructure in a way that in the future we will be able to add one more layer to our system - trading bot that can automatically manage simple recurring orders or automatically switch between different strategies via custom complex meta strategies',
            image: '/images/about-2-01.png',
            textToRight: true
        },
    ],
    featuresWithIcons: {
        title: 'Most important features',
        features: []
    },
    faqIntro: 'IFELSE is rapidly evolving to become ultimate trading platform that helps you to execute the best trades. Here are some other details obout IFELSE platform',
    faq: [
        {
            question: 'What signal types are supported?',
            answer: 'Currently we support such signals as asset price, asset candles, order statuses, date and time and external signals via WebHook (ex. from TradingView). In a future we will implement multiple other signals such as TA indicators, On Chain signals, Coingecko sentiments and other type of signals that our community will vote for.'
        },
        {
            question: 'What are template orders?',
            answer: 'You can create your own trade templates or even whole template strategies. They can be specific for some asset or abstract one, they can rely on any supported conditions and be based on eg. 5% percentage of your portfolio'
        },
        {
            question: 'What are batch orders?',
            answer: 'You can create your own trade templates or even whole template strategies. For example, if you want to have a template for gradually buy any asset for 3% of your actual capital in three consecutive orders, set 4 stop losses based on candle patterns and 5 take profit orders based on price movements, we cover your idea. Just create template strategy with multiple orders and you can reuse it by clicking only one button at any market condition for any given asset.'
        },
        {
            question: 'What exchanges are integrated?',
            answer: 'IFELSE app is independent from any particular exchange and we will integrate multiple centralised and decentralised exchanges such as Binance, Kucoin, Uniswap and PancakeSwap. Currently we support Binance Spot trading platform and partially Kucoin and Ethereum Wallet (for reading your portfolio)'
        },
        {
            question: 'What IFELSE token will be used for?',
            answer: 'IFELSE token is an utility token. I will be used for paying for trades and strategies in IFELSE App. Staking IFELSE will give you various bonuses. Stakers of IFELSE will be able to vote on proposals and allocations from the IFELSE Foundation fund.'
        },
    ],
    navigation: [
        {
            text: 'About',
            link: '#multiple-income-05'
        },
        // {
        //     text: 'Token sale',
        //     link: '#token-sale'
        // },
        {
            text: 'Roadmap',
            link: '#roadmap_05'
        },
        {
            text: 'Faq',
            link: '#faq-area-05'
        },
        {
            text: 'Tokens',
            link: '#token_distribution_06'
        },
        {
            text: 'Team',
            link: '#team_membar_02'
        }
    ],
    roadMap: {
        title: 'Project Roadmap',
        subtitle: 'We already implemented a lot. Still there are many things to do',
        events: [
            {
                title: 'Development started',
                date: 'March 2020',
                elements: [
                    'Concept created',
                    'Initial infrastructure built',
                    'Development started'
                ]
            },
            {
                title: 'Token creation',
                date: 'Sep 2021',
                elements: [
                    'Token creation',
                    'Initial distribution',
                    'Initial token farming'
                ]
            },
            {
                title: 'Go Public',
                date: 'Dec 2021',
                elements: [
                    'Open access for all users'
                ]
            },
            {
                title: 'More CEX and Dex integration',
                date: 'Feb 2022',
                elements: [
                    'Add other CEX integration',
                    'Add DEXes from multiple chains',
                ]
            },
            {
                title: 'Integrate more signals',
                date: 'May 2022',
                elements: [
                    'Add chain signals',
                    'Add market sentiments',
                    'Add TA signals',
                    'Add bot for executing strategies'
                ]
            }
        ]
    },
    teams: {
        title: 'Our Team',
        subtitle: 'Meet our experienced developers, advisors and partners',
        members: [
            {
                name: 'Krzysztof Saja, Ph.D.',
                description: 'Founder / CEO / Dev',
                image: '/images/team/team-01.jpg',
                linkedin: 'https://www.linkedin.com/in/krzysztof-saja/'
            },
            {
                name: 'Jan Kowalski',
                description: 'Developer',
                image: '/images/team/team-02.jpg',
                linkedin: 'https://www.linkedin.com/in/krzysztof-saja/'
            },
            {
                name: 'Mariola Kowalska',
                description: 'Developer',
                image: '/images/team/team-03.jpg',
                linkedin: 'https://www.linkedin.com/in/krzysztof-saja/'
            }
        ]
    }
};