import * as React from 'react';
import { PAGE_DATA } from '../text-content/content';

function FaqElement(props: any) {
  return props.data.map((el: {question: string, answer: string}, index: number) =>
    <div
      key={'accordion_' + index + 1}
      className={`panel accordion-single accordion-${index + 1} wow fadeInUp`}
      data-wow-duration="2s"
      data-wow-delay={`0.${index + 1}s`}
    >
      <div className="panel-heading" role="tab" id={`accordion-heading-${index+ 1}`}>
        <h4 className="panel-title">
          <a
            className="collapsed"
            data-toggle="collapse"
            data-parent="#accordion"
            href={`#accordion-collapse-${index+ 1}`}
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            {el.question}
          </a>
        </h4>
      </div>
      <div
        id={`accordion-collapse-${index+ 1}`}
        className="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby="headingTwo"
      >
        <div className="card-body accordion-content">
          <p>
            {el.answer}
          </p>
        </div>
      </div>
    </div>);
}

function Faq5(props: Pick<typeof PAGE_DATA, 'faq' | 'faqIntro'>) {
  const FAQ = props.faq || [];

  return <section id="faq-area-05" className="faq-area">
    <div className="container">
      <div className="row">
        <div className="col-sm-5">
          <div className="faq-img  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
            <img src="images/faq-02.png" alt="" className="img-fluid" />
          </div>
          <div className="sub-title">
            <h2 className=" wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
              Frequently Asked Questions
            </h2>
            <p className=" wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
              {props.faqIntro}
            </p>
          </div>
        </div>
        <div className="col-sm-7">
          <div className="faq-wrapper">
            <div
              className="panel-group accordion-wrapper"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <FaqElement data={FAQ}></FaqElement>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>;

}


export default Faq5;
