import * as React from 'react';

function ShapeBackground(props: {}) {
  return (
    <div className="shape-mask">
        <span className="shape1 header-shape">
        <img src="images/shape/header5_shape_1.png" alt="" />
      </span>
      <span className="shape2 header-shape">
        <img src="images/shape/header5_shape_2.png" alt="" />
      </span>
      <span className="shape3 header-shape">
        <img src="images/shape/header5_shape_3.png" alt="" />
      </span>
    </div>
  );
}

export default ShapeBackground;