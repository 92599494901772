import * as React from 'react';
import {useContext} from "react";
import {PageContentContext} from "../pages/page-content.context";

function Roadmap5(props: any) {
  const data = useContext(PageContentContext);
  const RoadmapEvents = data.roadMap.events.map((e, index) => {
    return <div className={`location location_bttom location_${index + 1} wow fadeInUp`}
                key={'roadmap-element' + index + 1}
                data-wow-duration={`0.${2}`}
                data-wow-delay={`0.${index + 3}`}
    >
      <div className="map_date">
        <h5>{e.date}</h5>
      </div>
      <span></span>
      <div className="location_title">
        <h3>{e.title}</h3>
        <ul>
          {e.elements.map((l, i) => <li key={'element' + i + 1}>{l}</li>)}
        </ul>
      </div>
    </div>
  });

  return <section id="roadmap_05">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="sub-title">
            <h2 className=" wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
              {data.roadMap?.title}
            </h2>
            <p className=" wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
              {data.roadMap?.subtitle}
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="location_04">
          <div className="col-md-12">
            <div className="location_wrapper">
              <div className="roadmap_position"></div>
              {RoadmapEvents}
            </div>
          </div>
        </div>
      </div>
    </div>
    <span className="shape1 header-shape">
      <img src="images/shape/roadmap5-01.png" alt="" />
    </span>
    <span className="shape2 header-shape">
      <img src="images/shape/roadmap5-02.png" alt="" />
    </span>
  </section>
}


export default Roadmap5;
