import * as React from 'react';
import {PAGE_DATA} from '../text-content/content';

type Features = Pick<typeof PAGE_DATA, 'plannedFeatures'>;
type Feature = Features extends readonly (infer T)[] ? T : any;

function FeatureRow(props: { feature: Feature, index: number }) {
    const FeatureImage =
        <div className={`about-img wow fadeInUp`} data-wow-duration="2s" data-wow-delay={`0.${props.index + 1}`}>
            <img src={props.feature.image} alt=""/>
        </div>;

    const FeatureText =
        <div className="about_cryptonic-content">
            <h2 className=" wow fadeInUp" data-wow-duration="2s" data-wow-delay={`0.${props.index + 1}`}>
                {props.feature.title}
            </h2>
            <p className=" wow fadeInUp" data-wow-duration="2s" data-wow-delay={`0.${props.index + 2}`}>
                {props.feature.description}
            </p>
        </div>;

    const fullWith = <div className={`row about-item-row ${props.feature.dark ? 'dark' : ''}`}>
        <div className="col-sm-12 flex-center">
            {FeatureText}
        </div>
        <div className="col-sm-12">
            {FeatureImage}
        </div>
    </div>;

    const textToLeft =
        <div className={`row about-item-row ${props.feature.dark ? 'dark' : ''}`}>
            <div className="col-sm-12 col-md-7 col-lg-5 flex-center">
                {FeatureText}
            </div>
            <div className="col-sm-12 col-md-5 col-lg-7">
                {FeatureImage}
            </div>
        </div>;

    const textToRight =
        <div className="row about-item-row">
            <div className="col-sm-12 col-md-5 col-lg-7 img-left">
                {FeatureImage}
            </div>
            <div className="col-sm-12 col-md-7 col-lg-5 flex-center">
                {FeatureText}
            </div>
        </div>;
    return props.feature.fullWidth ? fullWith : props.feature.textToRight ? textToRight : textToLeft;
}


function FeaturesWithImage(props: Features) {
    const Rows = props.plannedFeatures.map((f, i) => {
        return <FeatureRow index={i} feature={f} key={`feature-row-${i + 1}`}></FeatureRow>;
    });
    return <section id="multiple-income-05">
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="title">In development</h1>
                    <p className="center">Currently we are working on multiple new features and improvements</p>
                </div>
            </div>
        </div>
        <div className="container">
            {Rows}
        </div>
    </section>;
}

export default FeaturesWithImage;
