import * as React from 'react';

import { useContext } from 'react';
import {PageContentContext} from "../pages/page-content.context";

function Navigation(props: {}) {
  const data = useContext(PageContentContext);
  const links = data.navigation.map((el, i) => {
    return <li className="nav-item" key={`nav-item-key-` + i + 1}>
      <a data-scroll href={el.link} className="nav-link">
        {el.text}
      </a>
    </li>;
  });

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-faded cripto_nav">
      <div className="container">
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <a className="navbar-brand" href="#">
          <img id="logo" src="/images/logos/logoifelse.svg" alt="logo" />
          <span className="logo-name">IFELSE</span>
        </a>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a data-scroll href="#header-05" className="nav-link active">
                Home
              </a>
            </li>
            {links}
          </ul>
        </div>
        <div className="language">
          <a href={data.appLink} className="token" target="_blank">
            Launch The App
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;