import * as React from 'react';
import {PAGE_DATA} from '../text-content/content';
import {useContext} from "react";
import {PageContentContext} from "../pages/page-content.context";

type Features = Pick<typeof PAGE_DATA, 'multiFeatures'>;
type Feature = Features extends readonly (infer T)[] ? T : any;

const FeatureText = (props: { f: any, index: number }) => {
        const Title =
            <h2 className="wow fadeInUp" data-wow-duration="2s" data-wow-delay={`0.${props.index + 1}`}>
                {props.f.title}
            </h2>
    const SubTitle =
        <h4 className="wow fadeInUp" data-wow-duration="2s" data-wow-delay={`0.${props.index + 1}`}>
            {props.f.subtitle}
        </h4>

        return <div className="feature-text-element">
            { props.f.title ? Title : ''}
            { props.f.subtitle ? SubTitle : ''}
            <p className=" wow fadeInUp" data-wow-duration="2s" data-wow-delay={`0.${props.index + 2}`}>
                {props.f.description}
            </p>
            {props.f.image && <div><img src={props.f.image} alt=""/></div>}
        </div>
    }
;

function FeatureRow(props: { feature: Feature, index: number }) {
    const FeatureImage =
        <div className="about-img wow fadeInUp" data-wow-duration="2s" data-wow-delay={`0.${props.index + 1}`}>
            <img src={props.feature.image} alt=""/>
        </div>;

    const fullWith = <div className={`row about-item-row ${props.feature.fullWidth ? 'full-width' : ''}`}>
        <div className="col-sm-12">
            <div className="about_cryptonic-content">
                {props.feature.text?.map((t: any, i: number) => <FeatureText key={`f-` + i} index={i}
                                                                             f={t}></FeatureText>)}
            </div>
        </div>
        <div className="col-sm-12">
            {props.feature.image && FeatureImage}
        </div>
    </div>;


    const textToLeft =
        <div className="row about-item-row">
            <div className="col-sm-12 col-md-7 col-lg-5 flex-center">
                <div className="about_cryptonic-content">
                    {props.feature.text?.map((t: any, i: number) => <FeatureText key={`f-` + i} index={i}
                                                                                 f={t}></FeatureText>)}
                </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-7">
                {props.feature.image && FeatureImage}
            </div>
        </div>;

    const textToRight =
        <div className="row about-item-row">
            <div className="col-sm-12 col-md-5 col-lg-7 img-left">
                {props.feature.image && FeatureImage}
            </div>
            <div className="col-sm-12 col-md-7 col-lg-5 flex-center">
                <div className="about_cryptonic-content">
                    {props.feature.text?.map((t: any, i: number) => <FeatureText key={`f-` + i} index={i}
                                                                                 f={t}></FeatureText>)}
                </div>
            </div>
        </div>;

    return <div className={props.feature.dark ? 'dark section' : 'section'}>
        <div className="container">
            {props.feature.fullWidth ? fullWith : props.feature.textToRight ? textToRight : textToLeft}
        </div>
    </div>

}


function MultiFeaturesWithImage(props: {}) {
    const data = useContext(PageContentContext);
    const Rows = data.multiFeatures?.features.map((f, i) => {
        return <FeatureRow index={i} feature={f} key={`feature-row-${i + 1}`}></FeatureRow>;
    });
    return <section id="multiple-income-05">
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="title">{data.multiFeatures.title}</h1>
                    <p className="subtitle">{data.multiFeatures.subtitle}</p>
                    <br/><br/>
                </div>
            </div>
        </div>
        {Rows}
    </section>;
}

export default MultiFeaturesWithImage;
