import * as React from 'react';
import { PageContentHero, PageContentNavigation } from '../text-content/content.interface';
import Hero from './hero';
import Navigation from './navigation';

const Header = (props: { navigation: PageContentNavigation[], hero: PageContentHero, appLink: string }) => (
  <header id="header-05" className="header">
    <Navigation></Navigation>
    <Hero hero={props.hero} appLink={props.appLink}></Hero>
  </header>
);

export default Header;
